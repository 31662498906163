/* You can add global styles to this file, and also import other style files */

@import '~jquery-nice-select/css/nice-select';
/* @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700'); */
html,
body {
    height: 100%;
}

body {
    font-family: 'Poppins', sans-serif !important;
    background-color: #f5f5f5;
}

.filter-select.post-code {
    max-height: 200px;
    overflow: auto;
    position: relative;
    z-index: 9;
}

.ib_v {
    display: inline-block;
    vertical-align: middle;
}

.pointer {
    cursor: pointer;
}

thead th {
    font-weight: 500 !important;
}

/* .copyright {
    bottom: 0px;
}

#main {
    padding-bottom: 100px;
} */

input::-webkit-calendar-picker-indicator {
    display: none;
}

.btn {
    font-weight: 500;
    font-size: 15px;
    text-transform: none !important;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-light:hover,
.btn-light:focus {
    color: #fff !important;
    background-color: #00a3da !important;
    border-color: #00a3da !important;
}

.text-danger {
    color: #dc3545 !important;
    font-size: 13px;
}

.navbar-brand img {
    max-width: 150px;
    margin-right: 35px;
}

.navbar-brand {
    border-right: 1px solid #f4f4f4;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    width: 76px;
    transition: all 0.3s ease-in-out 0s;
}
.navbar-light.opened-logo .navbar-brand {width: 206px;}
.navbar-light.opened-logo .navbar-brand svg .logo-text {opacity: 1;}
.abs ng-scrollbar-y {
    position: absolute;
    right: 0;
}

.pos-abs {
    position: absolute;
    top: 50%;
    margin-top: -160px;
    left: 50%;
    margin-left: -166px;
}

.navbar {
    padding: 0px 35px;
    background-color: #ffffff !important;
}

.modal-backdrop.show {
    opacity: .8;
}

.height-280 {
    height: 280px;
}

.modal-dialog.max-401 {
    max-width: 401px;
}

.modal-dialog.max-416 {
    max-width: 416px;
}

.modal-dialog.max-401 {
    display: flex;
    align-items: center;
    height: calc(100vh - 60px);
}

.shadow-sm {
    box-shadow: 1.5px 2.6px 5px rgba(0, 0, 0, .075) !important;
}

.header-search {
    font-size: 16px;
    color: rgba(80, 86, 89, 0.4);
}

button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

.header-search::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 16px;
    color: rgba(80, 86, 89, 0.4)
}

.header-search::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 16px;
    color: rgba(80, 86, 89, 0.4)
}

.header-search:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 16px;
    color: rgba(80, 86, 89, 0.4)
}

.header-search:-moz-placeholder {
    /* Firefox 18- */
    font-size: 16px;
    color: rgba(80, 86, 89, 0.4)
}

.header-search.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0px 2px 0 0px rgba(0, 123, 255, .25);
}

.noti {
    background-color: rgba(216, 18, 125, 1);
    width: 17px;
    font-size: 11px;
    font-weight: 400;
    height: 17px;
    right: 0;
    top: 3px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}

.navbar-nav .nav-item {
    padding-left: 15px;
    padding-right: 15px;
}

.navbar-nav .dropdown-menu {
    left: auto;
    right: 0;
}

.bg-dark {
    background-color: #0d0c0d !important;
}

.sidebar .nav-link {
    font-size: 0;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #707070;
    padding: 18px 0 18px 14%;
    text-align: center;
    height: 73px;
}

.sidebar .nav-item {
    padding: 0; 
    height: 73px;
}

.sidebar .nav-link.active,
.sidebar .nav-link:hover {
    background-color: #d8127d;
}

.sidebar .nav-link:hover svg path,
.sidebar .nav-link.active svg path {
    fill: #ffffff;
}

.sidebar .nav-link:hover svg.stroke path,
.sidebar .nav-link.active svg.stroke path {
    stroke: #ffffff;
}

.sidebar .nav-link svg {
    margin-top: -4px;
    width: 34px;
    height: 44px;
    transition: width 0.25s, height 0.25s;
}

.sidebar .nav-link.active,
.sidebar .nav-link:hover {
    color: #ffffff;
}

.card {
    border-radius: .30rem;
}

.theme-green .bs-datepicker-head {
    background-color: #00a3da;
}

.bs-datepicker-container {
    padding: 0px;
}

.theme-green .bs-datepicker-body table td.week span {
    color: #00a3da;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: #00a3da;
}

/*table left align*/
.table th, .table td {text-align: left !important;}

/*table left align*/
ng-scrollbar-x .ng-scrollbar {
    height: 15px !important;
}
ng-scrollbar-y .ng-scrollbar {
    width: 15px !important;
}
ng-scrollbar-x .ng-scrollbar-thumb, ng-scrollbar-y .ng-scrollbar-thumb {border-radius: 50px;}
.custom-nice {
    clear: inherit;
    line-height: 36px;
    padding: 0 0 0 10px !important;
    height: 40px !important;
    width: 150px;
    margin-bottom: 23px;
}
.evidence-select{
    line-height: 36px;
    padding: 0 0 0 10px !important;
    height: 40px !important;
    width: 100%;
}
@media (min-width:1481px) {
    .max-250 {
        max-width: 13.5%;
    }
    .col-lg-9 {
        flex: auto;
        max-width: 86.5%;
    }
}

@media (max-width:1480px) {
    .max-250 {
        max-width: 16%;
    }
    .col-lg-9 {
        flex: auto;
        max-width: 84%;
    }
}

/* @media (min-width: 1500px) {
    .col-lg-9 {
        flex: auto;
        max-width: 84%;
    }
} */

@media (min-width: 1366px) and (max-width:1440px) {
    label {
        font-size: 12px !important;
    }
    .nice-select, .filter select, .filter input {
        font-size: 12px !important;
    }
    ::-webkit-input-placeholder { /* Edge */
        font-size: 12px !important;
      }
      
      :-ms-input-placeholder { /* Internet Explorer */
        font-size: 12px !important;
      }
      
      ::placeholder {
        font-size: 12px !important;
      }
      
    .table td {
        padding-top: 14px !important;
        padding-bottom: 14px !important;
    }
    .max-height-125 .col-3.pr-0.pl-0.border-right.pr-4 {
        padding-right: 10px !important;
    }
    .max-height-125 label {
        font-size: 11px !important;
    }
    .max-height-125 h4 {
        font-size: 12px !important;
    }
    .max-height-125 h2 {
        font-size: 21px !important;
    }
    .col-lg-9 {
        flex: auto;
        max-width: 84%;
    }
    .sidebar .nav-item {
        padding: 0px;
    }
    /* .sidebar .nav-link {
        padding: 23px 42px;
    } */
}

agm-map {
    height: 160px;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.0);
}
.navbar-light .navbar-brand {height: 65px;position: relative;left: -7px;top: 0px;margin-right: 0px;margin-right: 0;}
.navbar-light .navbar-brand svg {width: 148px;}
.navbar-light .navbar-brand svg .logo-text {opacity: 0;transition: all 0.3s ease-in-out 0s;}
select {
    font-size: 14px;
    padding-top: 8px;
    height: auto;
    padding-bottom: 8px;
    color: #373737;
    border: 2px solid #f5f5f5;
    border-radius: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-image: url(assets/images/arrow.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    text-overflow: '';
}

select::-ms-expand {
    display: none;
}

.shadow-custom {
    -webkit-box-shadow: 1.5px 2.6px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 1.5px 2.6px 5px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 1.5px 2.6px 5px 0px rgba(0, 0, 0, 0.08);
}

.datepick {
    background-image: url(assets/images/arrow-3.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
}

a {
    color: #00a3da;
}

a:hover {
    color: #00a3da;
    text-decoration: none;
}

.blue-color {
    color: #00a3da;
}

.form-control:focus {
    outline: 0;
    box-shadow: 0px 2px 0 0px rgba(0, 123, 255, .0);
}

.max-h-540 {
    max-height: 540px;
}

.login-shadow {
    -webkit-box-shadow: 1.5px 2.6px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 1.5px 2.6px 5px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 1.5px 2.6px 5px 0px rgba(0, 0, 0, 0.08);
}

.logo-bg-login {
    background-color: #fafbfd;
}

.border-secondary {
    border: 1px solid rgba(231, 240, 249, 0.5) !important;
}

.signin-form h2 {
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.73;
    letter-spacing: normal;
    color: #263238;
}

.signin-form input {
    border-width: 0px 0 1px 0;
    border-color: rgba(151, 151, 151, 0.4);
    border-radius: 0px;
}

.signin-form input:focus {
    box-shadow: none;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: rgba(0, 163, 218);
    background-color: rgba(0, 163, 218);
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 163, 218, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: rgba(216, 18, 125, 0.25);
}

.font-weight-500 {
    font-weight: 500 !important;
}

/*floating labels css*/

.has-float-label {
    display: block;
    position: relative
}

.has-float-label label,
.has-float-label>span {
    position: absolute;
    cursor: text;
    font-size: 75%;
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    top: -1em;
    left: 0px;
    z-index: 3;
    line-height: 1;
    padding: 0 1px
}

.has-float-label label::after,
.has-float-label>span::after {
    content: " ";
    display: block;
    position: absolute;
    background: #fff;
    height: 2px;
    top: 50%;
    left: -.2em;
    right: -.2em;
    z-index: -1
}

.has-float-label .form-control::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s
}

.has-float-label .form-control::-moz-placeholder {
    opacity: 1;
    transition: all .2s
}

.has-float-label .form-control:-ms-input-placeholder {
    opacity: 1;
    transition: all .2s
}

.has-float-label .form-control::placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
    opacity: 0
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
    opacity: 0
}

.has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
    opacity: 0
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
    opacity: 0
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}

@media (max-width:1440px) {
    .has-float-label label,
    .has-float-label>span {
        font-size: 11px !important;
    }
    .has-float-label .form-control:placeholder-shown:not(:focus)+* {
        font-size: 11px !important;
    }
    .has-float-label .form-control:-ms-input-placeholder:not(:focus)+* {
        font-size: 11px !important;
    }
}

.has-float-label .form-control:placeholder-shown:not(:focus)+* {
    pointer-events: none;
    font-size: 12px;
    opacity: .5;
    left: 0;
    top: .3em;
    font-weight: 400
}

.has-float-label .form-control.ng-valid~label {
    top: -1em;
}

.has-float-label .form-control:-ms-input-placeholder:not(:focus)+* {
    pointer-events: none;
    font-size: 13px;
    opacity: .5;
    left: 0;
    top: .3em;
    font-weight: 400
}

.logo {
    max-width: 176px;
    max-height: 53px;
}

.input-group .has-float-label {
    display: table-cell
}

.input-group .has-float-label .form-control {
    border-radius: 4px
}

.input-group .has-float-label:not(:last-child) .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group .has-float-label:not(:first-child) .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px
}

/* floating labels css ends*/

.modal-content {
    padding: 30px;
}

.modal-header {
    padding: 0px;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #293e44;
    border-bottom: 0px solid #e9ecef;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 720px;
        margin: 1.75rem auto;
    }
}

.cursor-pointer {
    cursor: pointer;
}

img:focus,
a:focus {
    box-shadow: none;
    outline: none;
}

button.btn.btn-outline-secondary {
    color: #c7c7c7;
    position: absolute;
    top: 5px;
    right: 0px;
    padding: 0px !important;
    font-size: 15px !important;
    border: 0px;
}

body .btn-outline-secondary:hover {
    background-color: transparent !important;
}

.range-icon {
    background-image: url(assets/images/range-icon.svg);
    background-position: center right 10px;
    background-repeat: no-repeat;
}

.ng5-slider .ng5-slider-pointer {
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    top: -7px !important;
    background-color: #fff !important;
    z-index: 3;
    border-radius: 16px;
    border: 1px solid #d8127d;
}

.ng5-slider .ng5-slider-pointer:after {
    display: none;
}

.ng5-slider .ng5-slider-bar {
    height: 7px !important;
    background: #e5e5e5 !important;
}

.ng5-slider .ng5-slider-selection {
    z-index: 2;
    background: #d8127d !important;
    border-radius: 2px;
}

.ng5-slider .ng5-slider-pointer:focus {
    outline: none;
}

.ng5-slider .ng5-slider-bubble.ng5-slider-limit {
    display: none;
}

.ng5-slider .ng5-slider-bubble {
    cursor: default;
    bottom: 16px;
    padding: 4px 9px !important;
    font-size: 11px !important;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.91;
    letter-spacing: normal;
    color: #535b5f !important;
    border: 1px solid #00a3da;
    border-radius: 4px;
}

.ng5-slider .ng5-slider-bubble {
    bottom: 21px !important;
    background: #FFFFFF;
}

.ng5-slider .ng5-slider-bubble:after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
    z-index: 1;
    margin-left: -4px;
}

.ng5-slider .ng5-slider-bubble:before {
    content: "";
    position: absolute;
    top: 18px;
    left: 50%;
    border-style: solid;
    border-width: 7px 7px 0;
    border-color: #00a3da transparent;
    display: block;
    width: 0;
    z-index: 0;
    margin-left: -5px;
}

.modal-body p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.44;
    text-align: center;
    color: #293e44;
}

.load-bar {
    z-index: 9999;
    position: relative;
}

.flash-messages {
    position: absolute;
    top: 11px;
    /* margin-top: -25%; */
    width: 100%;
}

.flash-messages p {
    margin-bottom: 0px;
}

.alert {
    padding: 1rem 1.25rem;
    text-align: center;
}

.ng-scrollbar-thumb {
    background-color: #e6e6e6 !important;
}

.alert-success {
    background-color: #84bf41;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.loader {
    text-align: center;
    width: 100%;
    float: left;
    font-size: 30px;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .6);
    top: 0px;
    left: 0px;
    color: #fff;
    padding-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.loader i {
    color: #fff;
}

/* #grayOutDiv {
    background-color: #333;
    opacity: 0.7;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 9999;
} */

.flash-message {
    z-index: 10000;
    position: relative;
}

#ns-toast-alert-container {
    position: fixed;
    top: 200px;
    right: 0;
    width: 400px;
    font-family: 'Helvetica';
}

.ns-toast-alert-toast {
    position: relative;
    background: #F5F5F5;
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    animation-duration: .3s;
    animation-name: VanillaToasts;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    width: 90%;
    /* Configure it fit in your design  */
    background-color: #FFFFFF;
    /* Default background */
    padding: 10px 20px;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    margin: 0 auto;
    font-size: 16px;
    right: 6%;
}

.ns-toast-alert-fadeOut {
    animation-name: VanillaToastsFadeOut;
    animation-duration: .3s;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-fill-mode: forwards;
}

#ns-toast-alert-container p,
#ns-toast-alert-container h4 {
    margin: 3px 0 !important;
}

.ns-toast-alert-title {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 10px;
}

.ns-toast-alert-text {
    font-size: 14px;
    color: #777;
}

.ns-toast-alert-icon {
    position: absolute;
    top: 5px;
    left: -40px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    background: #FFF;
}

.ns-toast-alert-toast a,
.ns-toast-alert-toast a:hover {
    color: #549EDB !important;
    text-decoration: none !important;
}

.ns-toast-alert-toast p {
    position: relative;
    padding-left: 35px;
}

.ns-toast-alert-toast p:before {
    font-family: 'fontAwesome';
    position: absolute;
    left: 0px;
    font-size: 25px;
    top: -5px;
}

/** toast types */

.ns-toast-alert-success {
    border-left: 3px solid #2b542c;
    background: #fff;
}

.ns-toast-alert-success p {
    color: #2b542c;
}

.ns-toast-alert-success p::before {
    content: '\f05d';
}

.ns-toast-alert-warning {
    border-left: 3px solid #f0ad4e;
}

.ns-toast-alert-warning p {
    color: #f0ad4e;
}

.ns-toast-alert-warning p::before {
    content: '\f071';
}

.ns-toast-alert-error {
    border-left: 3px solid #DB2B1D;
}

.ns-toast-alert-error p {
    color: #DB2B1D;
}

.ns-toast-alert-error p::before {
    content: '\f00d';
    margin-top: -4px;
}

.ns-toast-alert-info {
    border-left: 3px solid #27ABDB;
}

.ns-toast-alert-info p {
    color: #27ABDB;
}

.ns-toast-alert-info p::before {
    content: '\f05a';
}

@keyframes VanillaToasts {
    from {
        transform: translate3d(400px, 0, 0);
        ;
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes VanillaToastsFadeOut {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        transform: translate3d(400px, 0, 0);
        opacity: 0;
    }
}

.panel-heading.card-header {
    padding: 0px;
    border-bottom: 0px;
}

.panel.card.panel-default {
    border: 0px;
    margin-top: 10px;
}

.panel-body.card-block.card-body {
    padding: 0px;
}

.panel-heading.card-header .card-header:first-child {
    border-radius: calc(.25rem - -2px) calc(.25rem - -2px) 0 0;
}

.w-1100 ng-scrollbar-x.ng-scrollbar-visible,
.w-1100 ng-scrollbar-y.ng-scrollbar-visible {
    display: block;
    position: absolute;
    bottom: 0px;
}

.evidance-modal.modal-dialog {
    max-width: 1280px;
}

.evidance-modal .modal-content {
    padding: 0px;
}

.evidance-modal .modal-header {
    background-color: #f6f6f6;
    padding: 17px 26px;
}

.evidance-modal .modal-title {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #293e44 !important;
}

.evidance-modal .modal-body {
    padding: 1.5rem 1.2rem;
}

.evidance-modal .alert-success {
    background-color: #f2f8ec;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: #84bf41;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #c1dda5;
}

.evidance-modal .card-header {
    font-size: 16px !important;
    color: #535b5f !important;
}

.modal .evidance-modal .col-4 p {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #363342 !important;
    height: 31px;
    width: 31px;
    text-align: center;
    border: 1px solid #d8167f !important;
    padding-top: 13px !important;
    text-align: center !important;
}

.modal-dialog.evidance-modal.evidance-modal-990 {
    max-width: 990px !important;
}

.evidance-modal .list-group-item {
    padding: 0 0px !important;
    background-color: transparent !important;
}

.evidance-modal .list-group-item img {
    height: 13px;
}

.evidance-modal .card {
    border: 1px solid #f3f3f3 !important;
}

.evidance-modal .list-group-flush li.list-group-item {
    padding: 19px 20px !important;
    border-bottom: 1px solid #f3f3f3;
}

.evidance-modal .list-group-flush li.list-group-item li.list-group-item {
    padding: 0 2px !important;
    border-bottom: 0px solid #f3f3f3;
}

.evidance-modal .list-group-flush li.list-group-item li.list-group-item img {
    height: 15px;
}

.evidance-modal small {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 1.1 !important;
    color: #a4a4a4 !important;
}

.modal .evidance-modal p {
    font-size: 14px !important;
    text-align: left !important;
    color: #535b5f !important;
}

.evidance-modal .max-width-150 {
    max-width: 90px !important;
}

.evidance-modal .max-width-200 {
    max-width: 130px !important;
}

.evidance-modal .d-flex.justify-content-center.border-right-1 {
    border-right: 1px solid #e9e9e9 !important;
}

.evidance-modal .card-footer {
    padding: 1.2rem 1.25rem;
    background-color: #f2fcff;
    border-top: #e0edf1;
}

.evidance-modal .alert-danger {
    background-color: #f6e9ea;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: #ab2430;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #e4b0b4;
}

ngb-rating.d-inline-flex:focus {
    outline: none;
}

.popover.right.in {
    box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.07);
    border: 0px;
    padding: 10px;
    min-width: 286px;
}

.popover.right.in .card-header {
    min-height: 100%;
    background-color: #f7e9ea;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: normal;
    color: #535b5f;
    border: 1px solid #e4c0c7;
    border-radius: 0px;
    padding: 10px;
}

.popover.right.in .col-4 p {
    font-size: 9px !important;
    font-weight: 500 !important;
    color: #363342 !important;
    text-align: center;
    border: 1px solid #d8167f !important;
    padding-top: 5px !important;
    width: 31px !important;
    height: 25px !important;
}

ngb-rating.d-inline-flex {
    background: #f8f8f8;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    margin-left: 5px;
    border: 1px solid transparent;
}

.active-row ngb-rating.d-inline-flex {
    background: #ffeff0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    margin-left: 5px;
    border: 1px solid #e4c0c7;
}

.evidance-modal .popover.right.in .card {
    border: 0px none !important;
}

.evidance-modal .popover.right.in span {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.52;
    color: #a4a4a4;
}

.popover.right.in[x-placement^=right]>.arrow,
.popover.right.in>.arrow {
    left: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0;
    top: 53px;
}

.popover.right.in[x-placement^=right]>.arrow::before,
.popover.right.in>.arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .08);
}

.popover.right.in[x-placement^=right]>.arrow::after,
.popover.right.in>.arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff;
}

.my-icon svg {
    width: 50px;
    height: 50px;
    border: 1px solid #d8127d;
    padding: 4px 4px 4px 4px;
    float: right;
    border-radius: 4px;
    width: 50px;
}

.select-button.dropdown-toggle::after {
    float: right;
    margin-top: 8px;
}

ul#dropdown-basics {
    left: 17px !important;
    width: 96%;
    padding: 0px;
}

ul#dropdown-basics.dropdown-item {
    padding-bottom: 0px;
    line-height: 14px;
    padding-left: 20px;
}

ul#dropdown-basics.dropdown-menu li {
    border-bottom: 1px solid #f5f5f5;
}

ul#dropdown-basics.dropdown-menu li:last-child {
    border-bottom: 0px solid #f5f5f5;
    margin-bottom: 0px;
}

[trackY=true]>.ng-scrollbar-layout>.ng-scrollbar-layout>.ng-scroll-view-container>.ng-scroll-view {
    overflow-y: auto !important;
    transform: none;
}

input[type=text]::-ms-clear {
    display: none;
}

.panel.panel-open .panel-heading.card-header:after {
    content: '+';
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -12px;
    font-size: 29px;
    color: #d8127d;
    line-height: 23px;
}

.panel .panel-heading.card-header {
    position: relative;
}

.panel .panel-heading.card-header:after {
    content: '-';
    position: absolute;
    line-height: 23px;
    right: 20px;
    top: 50%;
    margin-top: -12px;
    font-size: 29px;
    color: #d8127d;
}

.modal-dialog {
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .modal-content {
        display: block;
    }
    .modal-content .modal-body p.border-danger {
        width: 57px !important;
    }
}

/* .height-220 .ng-scroll-view {
    width: 100% !important;
} */

.max-430 {
    max-width: 430px;
}

.max-430 p {
    font-size: 16px !important;
    font-weight: normal;
    color: #30373e !important;
    text-align: left !important;
}

hr {
    border-top: 1px solid #dcdcdc;
}

select:focus {
    outline: none;
}

.height-427 .panel.card.panel-default {
    border: 1px solid #ededed;
}

.height-427 .panel-body.card-block.card-body {
    padding: 20px 5px 5px;
    background: #fcfcfc;
}

.panel.panel-open .panel-heading.card-header:after {
    content: '' !important;
    position: absolute;
    right: 102px;
    top: 50%;
    margin-top: -12px;
    font-size: 29px;
    color: #d8127d;
    line-height: 23px;
    background-image: url(/assets/images/up-arrow-new.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
}

.panel .panel-heading.card-header:after {
    content: '' !important;
    position: absolute;
    line-height: 23px;
    right: 102px;
    top: 50%;
    margin-top: -12px;
    font-size: 29px;
    color: #d8127d;
    background-image: url(/assets/images/down-arrow-new.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
}

.height-427 accordion.panel-group {
    position: relative;
    width: 100%;
}

.height-427 .btn-group {
    position: absolute;
    z-index: 999;
    top: 16px;
    right: 0px;
    border-left: 1px solid #d2d2d2;
    padding-left: 4px;
    padding-right: 4px;
}

.height-427 button#button-basic {
    padding-top: 0px;
    padding-bottom: 0px;
}

.height-427 .dropdown-toggle::after {
    display: none;
}

.height-427 .dropdown-menu.show {
    left: auto !important;
    right: 0 !important;
}

.modal-dialog.max-430.p-0 .modal-content {
    padding-left: 0px;
    padding-right: 0px;
}

.modal-dialog.max-430.p-0 .modal-header {
    padding-left: 30px;
    padding-right: 30px;
}

.modal-dialog.max-430.p-0 li {
    padding-left: 30px !important;
    padding-right: 30px;
}

.ql-toolbar.ql-snow {
    border: 1px solid #d1d1d1;
    padding: 12px;
    background: #fcfcfc;
}

.ql-container.ql-snow {
    border-color: #d1d1d1;
    border-width: 0 0 1px 0;
}

.ql-editor {
    min-height: 300px;
    max-height: 370px;
}

quill-editor {
    width: 100%;
    float: left;
}

/* add-new-role */

.add-new-role {
    max-width: 490px;
}

.add-new-role h4 {
    font-family: Poppins;
    font-size: 22px;
    color: #293e44;
    font-weight: 600;
}

.add-new-role h2 {
    border-bottom: 1px solid #e0e0e0;
    font-family: Poppins;
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: 500;
    color: #535b5f;
    margin-top: 15px;
    margin-bottom: 25px;
}

.add-new-role .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.add-new-role .custom-control label {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 300;
    padding: 1px 0 0 0;
}

.add-new-role .custom-control {
    margin-bottom: 22px;
}

.add-new-role .button-con {
    text-align: right;
    float: left;
    width: 100%;
    margin-top: 12px;
}

.add-new-role .button-con .cancel-btn {
    margin-right: 20px;
    background: transparent;
    color: #ee007f;
}

.add-new-role .button-con .btn-secondary {
    padding-left: 25px;
    padding-right: 25px;
}

/* add-new-role */

.show-fadded .panel-title span.tab-title,
.show-fadded .card-block {
    cursor: not-allowed;
    opacity: 0.4;
}

.post-code {
    border: 1px solid #ced4da !important;
    padding: 10px !important;
    list-style: none;
    font-size: 14px;
}

@media (max-width:1500px) {
    /* .sidebar .nav-link {
        padding: 23px 35px;
    } */
    .sidebar .nav-item {
        padding-right: 0px !important;
    }
    .col-lg-9 {
        flex: 0 0 84%;
        max-width: 84%;
    }
    .nav-item {
        padding-right: 18px !important;
    }
    .btn {
        font-size: 12px;
    }
    .nice-select {
        font-size: 12px !important;
    }
    .approval-container .btn-default {
        min-width: 114px !important;
        font-size: 11px !important;
    }
    small {
        font-size: 11px !important;
    }
    .box.h-100 {
        min-height: 115px !important;
    }
    .awaiting-a h3 {
        font-size: 21px !important;
        line-height: 25px !important;
    }
    .awaiting-a .col-1 {
        flex: 13.333333%;
        max-width: 13.333333%;
    }
    .tags p {
        padding: 0 5px;
    }
    h4 {
        font-size: 15px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    .col-sm-6.m--16 {
        flex: 0 0 60%;
        max-width: 60%;
    }
    .abs ng-scrollbar-y {
        position: absolute;
        right: 0;
    }
    .panel.panel-open .panel-heading.card-header:after,
    .panel .panel-heading.card-header:after {
        right: 140px;
        top: 57%;
    }
    .max-width-200 .col-md-8.float-left,
    .max-width-200 .col-md-4.float-left {
        width: 100%;
        max-width: 100%;
    }
    .col-sm-2 .btn {
        padding: 8px 11px 10px !important;
        font-size: 12px !important;
    }
    .w-100 p.border-danger {
        line-height: 13px !important;
        height: 27px !important;
        padding-top: 7px !important;
    }
    .evidence-list .card-body .pr-5 {
        padding-right: 0.5rem !important;
    }
    .table th {
        font-size: 13px !important;
    }
    .table td {
        font-size: 13px !important;
    }
    h2 {
        font-size: 20px !important
    }
    h4,
    h3,
    h5 {
        font-size: 16px !important
    }
    .compression {
        padding-top: 22px !important;
    }
    .clr-fltr {
        font-size: 13px !important;
    }
    .campain-list .nice-select {
        height: 34px !important;
    }
    .card-body.filter {
        padding-top: 9px !important;
        padding-bottom: 16px !important;
    }
    .btn {
        height: 34px !important;
    }
    .compresssion-card-body .row.pb-3 {
        padding-bottom: 15px !important;
    }
    thead th {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
     
  
.main-container {
  padding-left: 100px !important;
}

.header-search::-webkit-input-placeholder { /* Edge */
  font-size: 14px !important;
}

.header-search:-ms-input-placeholder { /* Internet Explorer */
  font-size: 14px !important;
}

.header-search::placeholder {
  font-size: 14px !important;
}
.navbar {
  padding: 0px 15px 0 36px !important;
}
.navbar-brand img {
  margin-right: 9px !important;
}
input.form-control.ml-sm-2.border-0.w-75.header-search.text-truncate {
  margin-left: 0px;
  padding-left: 0px;
}
.navbar-nav .user-drop-down {
  margin-top:0px;
  display: flex;
  align-items: center;
}
.navbar-nav .user-drop-down a{
  margin-top:0px !important;
}
.navbar-brand {
    padding-top: 7px;
    padding-bottom: 8px;
}
label {
    font-size: 12px;
}
.campain-list .nice-select {
    line-height: 30px;
}
.filter select, .filter input {
    padding-top: 6px;
    padding-bottom: 6px;
}
}

.panel.panel-open .panel-heading.card-header:after,
.panel .panel-heading.card-header:after {
    right: 140px;
    top: 57%;
}

.randp .card.p-0.mb-0 {
    border: 0px !important;
}

.randp li.list-group-item {
    border-width: 0 0 1px 0 !important;
    border-radius: 0px !important;
    margin: 0px !important;
    border-color: rgba(0, 0, 0, 0.05);
}

.randp li.list-group-item:last-child {
    border-width: 0 0 0px 0 !important;
}

.randp li.list-group-item a {
    color: rgba(0, 0, 0, 0.6);
}

.randp .popover .arrow {
    top: -9px;
    left: 50%;
    margin-left: -8px;
}

.randp .card-body {
    padding: 0px;
}

.randp .popover.bottom.in {
    box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.07);
    border: 0px;
    padding: 10px;
    min-width: auto;
    margin-top: 4px;
}

.randp .popover.top.in {
    box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.07);
    border: 0px;
    padding: 10px;
    min-width: auto;
    margin-bottom: 4px;
}

.randp .popover.bottom.in[x-placement^=auto]>.arrow::before,
.randp .popover.bottom.in>.arrow::before {
    border-bottom: 6px solid #00a3da;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.randp .popover.top.in[x-placement^=auto]>.arrow::before,
.randp .popover.top.in>.arrow::before {
    border-top: 6px solid #00a3da;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.randp .popover.top.in .arrow {
    top: auto;
    bottom: -9px;
    left: 50%;
    margin-left: -8px;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    quill-editor {
        margin-top: 100px;
        position: relative;
    }
    .extra-spacing {
        position: absolute;
        bottom: 0px;
    }
    ng-scrollbar-x .ng-scrollbar-thumb {
        height: 5px !important;
    }
    ng-scrollbar-x .ng-scrollbar {
        padding: 7px;
    }
}

.overflow-y {
    overflow-y: auto;
}

.table td.admin-notes {
    width: 230px !important;
    float: left !important;
    height: 64px !important;
    float: left;
    overflow: hidden;
    text-transform: lowercase !important;
    position: relative;
    max-height: 64px !important;
    margin-right: -1em;
    padding-right: 1em;
}

.navbar-light .navbar-nav .nav-link {
    color: #293e44 !important;
    font-weight: 500;
}

/* .table td.admin-notes p {
    position: relative;
    margin-right: -1em;
    padding-right: 1em;
}

.table td.admin-notes p:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
}

.table td.admin-notes p:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
} */

b,
strong {
    font-weight: 600;
}

.ngx-dropdown-button {
    font-size: 14px;
    padding-top: 8px !important;
    height: auto;
    padding-bottom: 8px !important;
    color: #373737 !important;
    border: 2px solid #f5f5f5 !important;
    border-radius: 0px !important;
    background-image: url(/assets/images/arrow.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
}

.brands-listing .popover-content {
    height: auto;
}

.brands-listing .popover {
    border-radius: 2px;
}

.notes-popup .popover {
    padding: 10px;
    margin-top: 6px;
}

.notes-popup .popover.bottom.in[x-placement^=auto]>.arrow::before,
.notes-popup .popover.bottom.in>.arrow::before {
    border-bottom: 6px solid #00a3da;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.notes-popup .popover .arrow {
    top: -9px;
    left: 50%;
    margin-left: -8px;
}

/* .notes-popup .popover-content {
    height: 124px;
} */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .popover-content .ng-scrollbar-layout {
        display: block !important;
    }
}

.web-modal .panel.panel-open .panel-heading.card-header:after,
.web-modal .panel .panel-heading.card-header:after {
    right: 10px;
}

.filter-select {
    line-height: 32px;
    text-indent: 0px;
    cursor: pointer;
    background-color: #fff;
    border-width: 0 0 1px 0;
    border-color: rgba(0, 0, 0, 0.07);
    padding: 5px 0px 5px 5px;
    border-radius: 0px;
    float: left;
    width: 100%;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
}

.filter-menu {
    max-height: 300px;
    overflow-y: auto;
    padding: 0;
    width: 100%;
}

.filter-menu a {
    padding: 10px;
    font-size: 14px;
    line-height: 1.67;
}

.filter-menu a:hover,
.filter-menu a:focus {
    color: #d8127d;
    background-color: transparent;
}

.table-popup .popover {
    width: 100%;
    max-width: 98%;
    left: 0px !important;
    height: auto;
    padding: 0px;
}

.table-popup .notes-popup .popover {
    width: 100%;
    max-width: 100%;
    padding: 10px;
}

.table-popup .notes-popup .popover-content {
    height: 124px;
}

.table-popup .popover-content {
    height: auto;
}

.table-popup .popover .arrow {
    top: -9px;
    left: 180px;
}

.outer-popup>.popover.bottom.in {
    left: 0 !important;
    max-width: 100%;
    width: 100%;
}

.outer-popup>.popover.bottom.in:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #cacaca transparent;
    position: absolute;
    left: 210px;
    top: -10px;
}

/* .notes-popup .popover-content {
    height: auto;
    max-height: 100px;
    overflow-y: auto !important;
} */

.notes-popup .popover-content p {
    margin-bottom: 3px;
    /* width: calc(100% - 15px); */
}

.modal-backdrop.show {
    z-index: 9991;
}

.modal-open .modal {
    z-index: 9999;
}

.ngx-dropdown-container .ngx-dropdown-list-container ul li {
    white-space: nowrap !important;
    font-size: 13px !important;
}

.ngx-dropdown-container .ngx-dropdown-list-container {
    width: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ngx-dropdown-container button {
    max-width: 130px;
}

.ngx-dropdown-container button span {
    width: 90%;
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
}

.media-paddbtm{
    padding-bottom: 3px !important; 
}
.nice-select {
    font-size: 14px;
    padding-top: 8px;
    height: auto;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: .75rem;
    color: #373737;
    border-bottom: 1px solid rgba(55, 55, 55, 0.1);
    border-radius: 0px;
    -moz-appearance: none;
    background: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
}

.web-modal .h-450.card-header:after,
.web-modal .h-450 .card-header:after {
    right: 32px !important;
}

.nice-select .disabled {
    cursor: pointer !important;
    pointer-events: all !important;
    color: #f00 !important;
}

.web-modal .modal-body .add-product-heading-popup>.panel-heading.card-header {
    background-color: #eefbff;
    color: #00a3da;
}

/*anand 27*/

.web-modal [trackY=true]>.ng-scrollbar-layout>.ng-scrollbar-layout>.ng-scroll-view-container>.ng-scroll-view {
    overflow-y: scroll !important;
    transform: none;
}

.web-modal .panel.panel-open .panel-heading.card-header:after {
    background-image: none;
}

.web-modal .panel .panel-heading.card-header:after {
    background-image: url(/assets/images/down-arrow-new.svg) !important;
    background-repeat: no-repeat;
    background-position: center;
}

.web-modal .panel.panel-open>.panel>.panel-heading.card-header:after {
    background-image: url(/assets/images/up-arrow-new.svg) !important;
    background-repeat: no-repeat;
    background-position: center;
}

.search-data-field {
    width: 610px !important;
    position: relative;
    float: left;
}

.search-data-field input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 10px;
    width: 100%;
    font-size: 13px;
    color: #263238;
    font-weight: 200;
    padding-right: 25px;
    margin-bottom: 5px;
}
.search-count {
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
    float: right;
}
.search-data-field input:focus {
    outline: none !important;
}

.search-data-field .ico {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.search-data-field .ico img {
    width: 15px;
    cursor: pointer;
}

.camp .panel.panel-open .panel-heading.card-header:after,
.camp .panel .panel-heading.card-header:after {
    right: 40px;
    margin-top: -15px;
}

.dropdown-menu {
    padding: 0;
    border-radius: 0;
    border: 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
}

.dropdown-menu a {
    font-size: 15px;
    color: #757575;
    font-weight: normal;
    line-height: 1.33;
    padding: 15px 15px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
}

ul.dropdown-menu.show {
    margin-top: 16px !important;
}

.media-btn ul.dropdown-menu.show {
    margin-top: 0px !important;
}

.dropdown-menu.show:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #f5f5f5;
    border-width: 11px;
    margin-left: -11px;
}

.dropdown-menu:after {
    border-color: rgba(136, 183, 213, 0) !important;
    border-bottom-color: #fff !important;
    border-width: 10px !important;
    margin-left: -10px !important;
}

.dropdown-menu:after,
.dropdown-menu:before {
    bottom: 100%;
    right: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.dropdown-menu.editing:after,
.dropdown-menu.editing:before {
    bottom: 100%;
    right: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ui-select-choices {
    display: block;
}

.btn-default:hover {
    border-color: #00a3da !important;
    color: #00a3da !important;
}

.nice-select:after {
    border-bottom: 0px solid #999;
    border-right: 0px solid #999;
    background-image: url(assets/images/nice-select.svg);
    content: '';
    display: block;
    height: 4px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: all 0.15s ease-in-out;
    width: 10px;
    background-position: center;
    background-repeat: no-repeat;
}

.nice-select.open:after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.nice-select .list {
    margin-top: 1px;
    box-shadow: 0 3px 8px 2px rgba(68, 68, 68, 0.08);
    width: 100%;
    max-height: 300px;
    overflow: auto;
}

.nice-select .option.selected {
    font-weight: normal;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
    border-color: #ccc;
}

.campain-list .nice-select {
    line-height: 36px;
    padding: 0 33px 0 10px;
    height: 40px;
    width: 100%;
}
.campain-list.store-filter .nice-select {min-width: 290px;}

.campain-list .nice-select.dropdown-toggle:after {
    border-top: 0px;
    border-left: 0em solid transparent;
    top: 23px !important;
}
.panel.card.add-product-heading-popup{
    border:0 none;
}
.add-popup .panel.card.panel-default {
    margin-top: 0px;
    padding: 8px;
    border-radius: 0px;
}
.add-popup .card-header {
    background-color: rgba(0,0,0,0);

}
.web-modal.add-popup .panel.panel-open>.panel>.panel-heading.card-header:after, .web-modal.add-popup .panel .panel-heading.card-header:after {
    background-size: 13px;
}
.web-modal .child-accord .panel .panel-heading.card-header:after {
    right: 10px !important;
}
.web-modal.add-popup .panel-body.card-block.card-body {
    border: 1px solid #ededed;
}
.media-assets .nice-select { padding: 0 30px 0 10px !important;
    height: 43px !important;
    min-width: 71px;}
.detail-arrow.panel.panel-open .panel-heading.card-header:after, .detail-arrow.panel .panel-heading.card-header:after { right: 20px;}


.w-1100 .evidences-scoller ng-scrollbar-x.ng-scrollbar-visible {padding: 0;}

.expand-btn {margin: 0 0 0 0;z-index: 2;position: relative;padding: 20px 14px 20px 30px;}
.sidebar-pannel.opened .sidebar .nav-link {font-size: 18px;text-align: left;transition: all 0.3s ease-in-out 0s;line-height: 36px;}
.sidebar-pannel.opened .sidebar .nav-link span {float: left; width: 27px; height: 25px;margin: 0 5px 0 0;}
.sidebar-pannel.opened .sidebar .nav-link svg {width: auto;height: auto;}
.menu-overlay { position: absolute;left: -112px;top: 5px;background: rgba(0, 0, 0, 0.5);width: 0;height: 100%;z-index: 9;transition: all 0.5s ease-in-out 0s;left: -110px;width: calc(100% - -125px);}
a:not([href]):not([tabindex]) {color: #707070;}